<template>
	<div>
		<v-row>
			<v-col
				cols="6"
			>
				<s-toolbar
					label="Lotes Para transferir"
				></s-toolbar>
				<v-data-table
					:headers="headerTransfer"
					:items="lotsTransfer"
					:single-select="singleSelect"
					item-key="RowNum"
					show-select
					@click:row="rowSelectTransfer($event)"
					v-model="selectTransfer"
				>

				</v-data-table>
				
			</v-col>
			<v-col
				cols="1"
			>
				<v-row>
					<v-col>
						<v-btn>
							<i class="fas fa-arrow-right"></i>
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn>
							<i class="fas fa-arrow-left"></i>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
			<v-col
				cols="5"
			>
				<s-toolbar
					label="Lotes Walmart"
				></s-toolbar>
				<v-data-table
					:headers="headerTransferWalmart"
					:items="lotsTransferWalmart"
					:single-select="singleSelectWalmart"
					item-key="RowNum"
					show-select
					@click:row="rowSelectTransferWalmart($event)"
					v-model="selectTransferWalmart"
				>

				</v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import _sPalletSend from "@/services/FreshProduction/PrfLotProductionCalibratedService.js"

	export default {
		data() {
			return {
				headerTransferWalmart: [
					{text:'ID', value:'RcfID'},
					{text:'Calibre', value:'Caliber'},
					{text:'Kilos', value:'WeightNet'},
				],
				lotsTransferWalmart: [],
				headerTransfer: [
					{text:'ID', value:'RcfID'},
					{text:'Calibre', value:'Caliber'},
					{text:'Kilos', value:'WeightNet'},
				],
				lotsTransfer: [],
				singleSelect: false,
				singleSelectWalmart: false,
				totalKilosTransfer: 0,
				totalKilosTransferWalmart:0,
				selectTransfer: [],
				selectTransferWalmart: [],
			}
		},

		watch: {
			selectTransfer(newValue, oldValue) {
				console.log('totaltransfer ', this.totalKilosTransfer);
			},

			selectTransferWalmart(newValue, oldValue) {
				console.log('totaltransferWalmart ', this.totalKilosTransferWalmart);
			},
		},

		methods: {
			rowSelectTransfer(index, value)
			{
				console.log('index', index);
			},

			rowSelectTransferWalmart()
			{

			},

			getLotsTransfer() {
				_sPalletSend
				.prflotprodsupplypalletsendtransfer(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						console.log('resp.adta ', resp.data)
						this.lotsTransfer = resp.data;
						this.lotsTransfer.forEach(element => {
							this.totalKilosTransfer += element.WeightNet
						})
					}
				})
				
			},

			getLotsTransferWalmart() {
				_sPalletSend
				.prflotprodsupplypalletsendtransferwalmart(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.lotsTransferWalmart = resp.data;
						this.lotsTransferWalmart.forEach(element => {
							this.totalKilosTransferWalmart += element.WeightNet;
						})
					}
				})
				
			},
		},

		created () {
			this.getLotsTransfer();
			this.getLotsTransferWalmart();
		},
	}
</script>
